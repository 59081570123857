<template>
  <div class="bookable-details py-8">
    <BookableAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <ViewingTitle :title="displayName" class="bookable-details__title" />
        <DeleteOrRestore
          v-if="hasDeletePermission"
          :model="bookable"
          name="service & space"
          redirect="/services-and-spaces"
        />
        <div class="d-flex mb-6">
          <v-tabs v-model="currentTab" hide-slider class="bookable-info-tabs">
            <v-tab class="pa-0" value="info">INFO</v-tab>
            <v-tab
              class="pa-0"
              v-if="isRequestBooking || isCalendarBooking"
              value="bookings"
              >BOOKINGS</v-tab
            >

            <v-spacer></v-spacer>
            <v-btn
              v-if="currentTab === 1 && isCalendarBooking"
              color="primary"
              class="appbar__btn notification-button"
              height="40px"
              right
              @click="onClickingAdd"
            >
              <v-icon class="mr-2">{{ icons.add }}</v-icon>
              ADD BOOKING
            </v-btn>
            <v-tabs-items v-model="currentTab">
              <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
                <v-divider class="bookable-details__divider"></v-divider>

                <BookableForm
                  class="bookable-details__form"
                  :bookable="bookable"
                  :showBookingTimeSlots="isCalendarBooking"
                />
              </v-tab-item>

              <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
                <v-divider class="bookable-details__divider"></v-divider>
                <booking-list></booking-list>
              </v-tab-item>
            </v-tabs-items>
          </v-tabs>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
    <div v-if="open">
      <AddingBookingModal
        :open="open"
        :name="displayName"
        @cancel="onCloseModal"
      />
    </div>
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View Bookable
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiPlus } from '@mdi/js'
import ViewingTitle from '@/components/common/ViewingTitle'
import BookableForm from '@/views/Home/Bookables/components/BookableForm'
import BookableAppbar from '@/views/Home/Bookables/components/BookableAppbar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import BookingList from './components/BookingList.vue'
import AddingBookingModal from './components/AddingBookingModal.vue'
import {
  BOOKING_CALENDAR,
  REQUEST_BOOKING,
} from '../../../utils/enums/BookingOption'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'

export default {
  name: 'BookableDetails',

  components: {
    Loading,
    BookingList,
    ViewingTitle,
    BookableAppbar,
    BookableForm,
    DeleteOrRestore,
    AddingBookingModal,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      open: false,
      loading: false,
      currentTab: null,
      icons: {
        add: mdiPlus,
      },
    }
  },

  computed: {
    ...mapState({
      bookable: (state) => state.bookable.bookableDetails,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.bookable ? this.bookable.title : null
    },

    hasDeletePermission() {
      return validatePermissions(
        [PERMISSION.BOOKABLES_DELETE],
        this.permissions
      )
    },

    isRequestBooking() {
      if (!this.bookable) return false
      return this.bookable.booking_option === REQUEST_BOOKING
    },

    isCalendarBooking() {
      if (!this.bookable) return false
      return this.bookable.booking_option === BOOKING_CALENDAR
    },
  },

  methods: {
    ...mapActions({
      getBookableDetails: 'bookable/getBookableDetails',
    }),

    ...mapMutations({
      clearBookableDetails: 'bookable/clearBookableDetails',
    }),

    async getBookable() {
      this.loading = true
      await this.getBookableDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    onClickingAdd() {
      this.open = true
    },

    onCloseModal() {
      this.open = false
    },
  },

  created() {
    this.getBookable()
  },

  destroyed() {
    this.clearBookableDetails()
  },

  watch: {
    $route() {
      this.getBookable()
    },
  },
}
</script>

<style lang="scss" scoped>
.bookable-details {
  &__title {
    padding-right: 10px;
  }

  &__divider {
    max-width: 400px;
  }

  &__uploader {
    //
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
